import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import parse from 'html-react-parser'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const BackToTop = loadable(() => import('/src/components/Structure/BackToTop'))
const SocialLinks = loadable(() => import('/src/components/Basic/SocialLinks'))

const Footer = () => {
    const [options] = useContext(OptionsContext)

    const footerOptions = options.footer
    const companyOptions = options.companyDetails

    const currentYear = new Date().getFullYear()

    return (
        <footer className="c-section c-footer">
            <div className="container-lg">
                <div className="row">
                    {footerOptions.column1.title && (
                        <div className="col-lg-3 text-center text-lg-left mt-1 mt-lg-0 mb-1 mb-lg-0">
                            <h5 class="d-none d-lg-block">{footerOptions.column1.title}</h5>

                            {footerOptions.column1.linksRepeater && (
                                <ul className="c-footer__link-list">
                                    {footerOptions.column1.linksRepeater.map((node, index) => (
                                        <li key={index}>
                                            <a href={node.link.url} target={node.link.target}>
                                                {parse(node.link.title)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                    {footerOptions.column2.title && (
                        <div className="col-lg-3 c-footer__column">
                            <h5>{footerOptions.column2.title}</h5>

                            {footerOptions.column2.linksRepeater && (
                                <ul className="c-footer__link-list">
                                    {footerOptions.column2.linksRepeater.map((node, index) => (
                                        <li key={index}>
                                            <a href={node.link.url} target={node.link.target}>
                                                {parse(node.link.title)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                    {footerOptions.column3.title && (
                        <div className="col-lg-3 c-footer__column">
                            <h5>{footerOptions.column3.title}</h5>
                            {parse(footerOptions.column3.content)}
                        </div>
                    )}
                    <div className="col-lg-3 c-footer__contact-column">
                        <StaticImage
                            src={'../../../images/pl-vertical-white.png'}
                            width={125}
                            alt={`${options.siteData.title} logo`}
                        />
                        <address>
                            <div className="c-footer__address">{parse(companyOptions.address)}</div>
                            <div className="c-footer__tel">
                                Tel:{' '}
                                <a href={`tel: ${companyOptions.telephoneNumber}`}>{companyOptions.telephoneNumber}</a>
                            </div>

                            <SocialLinks />
                        </address>
                    </div>
                </div>
                {footerOptions.copyright && (
                    <div className="c-footer__copyright">
                        <p>
                            Copyright &copy; {options.siteData.title} 2010 - {currentYear}
                        </p>
                        {parse(footerOptions.copyright)}
                    </div>
                )}
            </div>

            <BackToTop showBelow={300} />
        </footer>
    )
}

export default Footer
